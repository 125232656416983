let PriceTable = [
    {
        id: 0,
        name: "Baby masáže",
        desc: "Kurzy výuky masáže miminek",
        time: "1 lekce",
        place: "Dle dohody",
        price: "350 Kč",
    },
    {
        id: 1,
        name: "Vaničkové plavání",
        desc: "Plavání kojenců v domácím prostředí (děti od 6 týdnů do 6 měsíců)",
        time: "1h vč. dopravy",
        place: "Dle dohody",
        price: "450 Kč",
    },
    {
        id: 2,
        name: "Individuální lekce",
        desc: "Plavání pro začátečníky i pokročilé (děti od 3 let a dospělí)",
        time: "1h",
        place: "Ostrava",
        price: "350 Kč",
    },
    {
        id: 3,
        name: "Plavání pro nejmenší",
        desc: "Plavání pro děti od 6 měs. do 3 let",
        time: "10 lekcí",
        place: "VODNÍ SVĚT SAREZA",
        price: "3 000 Kč",
    },
    {
        id: 4,
        name: "",
        desc: "Plavání pro děti od 6 měs. do 3 let + NEOPRÉNOVÉ PLAVKY",
        time: "10 lekcí",
        place: "VODNÍ SVĚT SAREZA",
        price: "3 300 Kč",
    },
    {
        id: 5,
        name: "Plavání pro děti",
        desc: "Plavání pro děti od 2 do 5 let s rodiči + PARNÍ SAUNA",
        time: "10 lekcí (30 minut)",
        place: "AD Landek",
        price: "2 600 Kč",
    },
    {
        id: 6,
        name: "",
        desc: "Plavání pro děti od 3 do 8 let (Zdokonalování) + PARNÍ SAUNA",
        time: "10 lekcí (45 minut)",
        place: "AD Landek",
        price: "2 600 Kč",
    },
    {
        id: 7,
        name: "Permanentky",
        desc: "Plavání pro děti od 4 do 8 let (zdokonalování)",
        time: "10 lekcí (50 minut)",
        place: "Ostrava",
        price: "3 000 Kč",
    },
    {
        id: 8,
        name: "",
        desc: "Plavání pro děti od 4 do 8 let (zdokonalování)",
        time: "15 lekcí (50 minut)",
        place: "Ostrava",
        price: "4 200 Kč",
    },
    {
        id: 9,
        name: "",
        desc: "Plavání pro děti od 6 mesíců do 3 let",
        time: "15 lekcí",
        place: "VODNÍ SVĚT SAREZA",
        price: "4 200 Kč",
    },
    {
        id: 10,
        name: "",
        desc: "Plavání pro děti od 6 měsíců do 3 let + NEOPRÉNOVÉ PLAVKY",
        time: "15 lekcí",
        place: "VODNÍ SVĚT SAREZA",
        price: "4 500 Kč",
    },
]

export default PriceTable