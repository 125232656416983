import MailIcon from '@mui/icons-material/Mail';
import Person2Icon from '@mui/icons-material/Person2';
import BusinessIcon from '@mui/icons-material/Business';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {useEffect, useRef, useState} from "react";
import { useForm } from "react-hook-form";
import {Modal, Ripple, initTE} from "tw-elements";
import emailjs from '@emailjs/browser';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from  'react-loader-spinner'
import ReCAPTCHA from "react-google-recaptcha"
import axios from 'axios';
//import { PatternFormat } from 'react-number-format';

const Contact = () => {

    const [submitText, setSubmitText] = useState("Závazně odeslat přihlášku")
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [themeToast, setThemeToast] = useState("light")
    const captchaRef = useRef(null)
    const form = useRef();
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm()
    const notify = () => toast.success("Přihláška byla odeslána.");
    const captchaErr = () => toast.warning("Musíte potvrdit, že nejste robot!");
    useEffect(() => {
        initTE({ Modal, Ripple });
    }, []);

    const onSubmit = async () => {

        const token = await captchaRef.current.executeAsync();
        //console.log(token)
        captchaRef.current.reset();

        await axios.post("https://davidjs.micro-home.cz/post", { token })
            .then(res => {
                const data = res;
                const nameFromResponse = data.data;

                console.log(data);
                console.log(nameFromResponse);
                localStorage.setItem("captchaStatus", nameFromResponse)
            })
            .catch((error) => {
                console.log(error);
            })

        if (localStorage.getItem("captchaStatus") === "Human")
        {
            setSubmitDisabled(true)
            setSubmitText(<ThreeDots
                height="30"
                width="30"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName="text-align: center"
                visible={true}
            /> )
            setThemeToast(localStorage.getItem("theme"))

                ///*Testovací*/emailjs.sendForm('service_nn0kly8', 'template_en4toml', form.current, 'ndxe6osmlsh-UKMiH')
                emailjs.sendForm('service_upi6tfs', 'template_t5zi0qk', form.current, 'VYtDUeS1GjvLEH-f2')
                .then((result) => {
                    console.log("Sending status: " + result.text);
                    form.current.reset()
                    notify()
                    setSubmitDisabled(false)
                    setSubmitText("Závazně odeslat přihlášku")
                }, (error) => {
                    console.log(error.text);
                });
        }
        else
        {
            captchaErr();
        }

    }

    const currentDate = new Date();
    const maxDate = new Date(currentDate);
    maxDate.setMonth(maxDate.getMonth() - 1);

    const maxDateFormatted = `${maxDate.getFullYear()}-${String(maxDate.getMonth() + 1).padStart(2, '0')}-${String(maxDate.getDate()).padStart(2, '0')}`;

    const selectedType = watch('type_lection');

    return (
      <section id="contact" className="flex bg-gradient-to-b from-fuchsia-100 to-fuchsia-50 rounded-t-[10%] rounded-b-[10%] dark:bg-gradient-to-b dark:from-gray-900 dark:to-gray-700">
          <div className="container m-auto px-6 py-20 md:px-12 lg:px-20" data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine">
              <div className="m-auto text-center lg:w-8/12 xl:w-7/12">
                  <h2 className="text-2xl text-pink-900 font-bold md:text-4xl pt-6 pb-8 dark:text-stone-50">Kontakt</h2>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-24 ">



                  <div className="max-h-[450px] rounded-xl bg-white shadow-md px-4 py-4 w-full dark:bg-gray-900">
                      <h2 className="text-l text-pink-900 font-bold md:text-xl pt-6 pb-2 pl-4 dark:text-white">Kontaktní údaje</h2>
                      <div className="h-1 w-[200px] bg-pink-800 rounded mb-4 ml-4 dark:bg-fuchsia-500"></div><br/>
                      <div>
                          <p className="font-bold pl-4 dark:text-stone-50">{<Person2Icon className="text-pink-900 mr-2 dark:text-white"/>} Marta Matoušková</p>
                          <p className="text-sm pt-2 pl-4 dark:text-stone-50">{<LocalPhoneIcon className="text-pink-900 mr-2 dark:text-white"/>} +420 722 194 894</p>
                          <p className="text-sm pt-2 pl-4 dark:text-stone-50">{<BusinessIcon className="text-pink-900 mr-2 dark:text-white"/>} 61948535</p> <br/>

                          <p className="font-bold mt-4 pl-4 dark:text-stone-50">{<Person2Icon className="text-pink-900 mr-2 dark:text-white"/>} Kateřina Chamrádová</p>
                          <p className="text-sm pt-2 pl-4 dark:text-stone-50">{<LocalPhoneIcon className="text-pink-900 mr-2 dark:text-white"/>} +420 602 541 280</p>
                          <p className="text-sm pt-2 pl-4 dark:text-stone-50">{<BusinessIcon className="text-pink-900 mr-2 dark:text-white"/>} 04745027</p> <br/>

                          <p className="text-sm sm:text-md font-thin mt-4 pl-4 dark:text-stone-50">{<MailIcon className="text-pink-900 mr-2 dark:text-white"/>} <a href="mailto:bc.chobotnicka@seznam.cz" className="underline">bc.chobotnicka@seznam.cz</a></p>
                      </div>
                  </div>

                  <div id="loginPaper">
                      <h2 className="text-xl text-pink-900 font-bold sm:mt-10 dark:text-white">Přihláška do kurzu plavání</h2><br/>
                      <form className="w-full max-w-lg" ref={form} onSubmit={handleSubmit(onSubmit)}>
                          <div className="flex flex-wrap -mx-3 mb-6">
                              <div className="w-full px-3">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-stone-50"
                                         htmlFor="grid-type">
                                      Typ kurzu
                                  </label>
                                  <div className="relative">
                                      <select data-te-select-init
                                              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 dark:bg-gray-900 dark:text-stone-50 dark:border-gray-900"
                                              id="grid-type" name="type_lection"
                                              {...register('type_lection', {
                                                  required: 'Musí být vyplňeno',
                                              })}
                                      >
                                          <option value=""/>
                                          <option value="Baby masáže">Baby masáže</option>
                                          <option value="Vaničkování">Vaničkování</option>
                                          <option value="Individuální lekce">Individuální lekce</option>
                                          <option value="Plavání pro nejmenší - od 6 do 36
                                              měsíců">Plavání pro nejmenší - od 6 do 36
                                              měsíců
                                          </option>
                                          <option value="Plavání pro nejmenší - od 6 do 36
                                              měsíců + plavky">Plavání pro nejmenší - od 6 do 36
                                              měsíců + plavky
                                          </option>
                                          <option value="Plavání pro děti - od 1 roku (AD
                                              Landek)">Plavání pro děti - od 1 roku (AD
                                              Landek)
                                          </option>
                                          <option value="Plavání pro děti - od 2 do 5 let + sauna">Plavání pro děti -
                                              od 2 do 5 let + sauna
                                          </option>
                                          <option value="Plavání pro děti - od 3 do 8 let + sauna">Plavání pro děti -
                                              od 3 do 8 let + sauna
                                          </option>
                                      </select>
                                      {errors.type_lection && (
                                          <p className="mt-2 text-xs border-2 rounded bg-red-300 border-red-300 text-white">
                                              <ReportGmailerrorredIcon/> {errors.type_lection.message}</p>)}

                                  </div>
                              </div>
                          </div>
                          <div className="flex flex-wrap -mx-3 mb-6 border-2 rounded-xl bg-white p-4 dark:bg-gray-900 dark:border-0">
                              <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-stone-50"
                                         htmlFor="grid-first-name-child">
                                      Jméno dítěte *
                                  </label>
                                  <input
                                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white dark:bg-gray-800 dark:text-stone-50 dark:border-gray-800"
                                      id="grid-first-name-child" type="text" placeholder="Malá" name="child_name"
                                      {...register('child_name', {
                                          required: 'Musí být vyplňeno',
                                      })}
                                  />
                                  {errors.child_name && (<p className="mb-2 text-xs border-2 rounded bg-red-300 border-red-300 text-white"><ReportGmailerrorredIcon/> {errors.child_name.message}</p>)}
                              </div>
                              <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-stone-50"
                                         htmlFor="grid-last-name-child">
                                      Přijmení dítěte *
                                  </label>
                                  <input
                                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 dark:bg-gray-800 dark:text-stone-50 dark:border-gray-800"
                                      id="grid-last-name-child" type="text" placeholder="Chobotnička" name="child_surname"
                                      {...register('child_surname', {
                                          required: 'Musí být vyplňeno',
                                      })}
                                  />
                                  {errors.child_surname && (<p className="mt-3 text-xs border-2 rounded bg-red-300 border-red-300 text-white"><ReportGmailerrorredIcon/> {errors.child_surname.message}</p>)}

                              </div>
                              <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-stone-50"
                                         htmlFor="grid-birth-date">
                                      Datum narození *
                                  </label>
                                  <input
                                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white dark:bg-gray-800 dark:text-stone-50 dark:border-gray-800"
                                      id="grid-birth-date" type="date" name="birth_date" min="1900-01-01" max={selectedType === 'Vaničkování' ? '' : maxDateFormatted}
                                      {...register('birth_date', {
                                          required: 'Musí být vyplňeno',
                                      })}
                                  />
                                  {errors.birth_date && (<p className="mt-3 text-xs border-2 rounded bg-red-300 border-red-300 text-white"><ReportGmailerrorredIcon/> {errors.birth_date.message}</p>)}

                              </div>
                              <div className="w-full md:w-1/2 px-3">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-stone-50"
                                         htmlFor="grid-pojistovna">
                                      Zdr. pojištovna *
                                  </label>
                                  <div className="relative">
                                      <select data-te-select-init className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 dark:bg-gray-800 dark:text-stone-50 dark:border-gray-800" id="grid-pojistovna" name="insurance"
                                              {...register('insurance', {
                                                  required: 'Musí být vyplňeno',
                                              })}
                                      >
                                          <option value=""></option>
                                          <option value="111 - VZP" >111 - VZP</option>
                                          <option value="201 - VOZP">201 - VOZP</option>
                                          <option value="205 - CZPZ">205 - CZPZ</option>
                                          <option value="207 - OZP" >207 - OZP</option>
                                          <option value="209 - ZPS" >209 - ZPS</option>
                                          <option value="211 - ZPMV">211 - ZPMV</option>
                                          <option value="213 - RBP" >213 - RBP</option>
                                      </select>
                                      {errors.insurance && (<p className="mt-3 text-xs border-2 rounded bg-red-300 border-red-300 text-white"><ReportGmailerrorredIcon/> {errors.insurance.message}</p>)}

                                  </div>
                              </div>
                                  <div className="w-full px-3 pt-4">
                                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-stone-50"
                                             htmlFor="grid-pojistovna">
                                          Zdravotní stav dítěte *
                                      </label>
                                      <textarea
                                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 dark:bg-gray-800 dark:text-stone-50 dark:border-gray-800"
                                          id="grid-note" type="" placeholder="Zdravá" name="message"
                                          {...register('message', {
                                              required: 'Musí být vyplňeno',
                                          })}
                                      />
                                      {errors.message && (<p className="mt-3 text-xs border-2 rounded bg-red-300 border-red-300 text-white"><ReportGmailerrorredIcon/> {errors.message.message}</p>)}

                                  </div>

                          </div>
                          <div className="flex flex-wrap -mx-3 mb-6">
                              <div className="w-full px-3">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-stone-50"
                                         htmlFor="grid-email">
                                      E-mail *
                                  </label>
                                  <input
                                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 dark:bg-gray-900 dark:text-stone-50 dark:border-gray-900"
                                      id="grid-email" type="email" placeholder="vasemail@tojeukazka.cz" autoComplete="on" name="email"
                                      {...register('email', {
                                          required: 'Musí být vyplňeno',
                                          pattern: {
                                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                              message: 'E-mail je chybně zadán',
                                          },
                                      })}
                                  />
                                  {errors.email && (<p className="text-xs border-2 rounded bg-red-300 border-red-300 text-white"><ReportGmailerrorredIcon/> {errors.email.message}</p>)}
                              </div>
                          </div>
                          <div className="flex flex-wrap -mx-3 mb-6">
                              <div className="w-full px-3">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-stone-50"
                                         htmlFor="grid-call">
                                      Mobilní číslo *
                                  </label>
                                  <input
                                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 dark:bg-gray-900 dark:text-stone-50 dark:border-gray-900"
                                      id="grid-call" type="tel" placeholder="123 456 789" name="tel" autoComplete="on"
                                      {...register('tel', {
                                          required: 'Musí být vyplňeno',
                                      })}
                                  />
                                  {/*<PatternFormat className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 dark:bg-gray-900 dark:text-stone-50 dark:border-gray-900" id="grid-call" type="tel" placeholder="123 456 789" name="tel" autoComplete="on" format="### ### ###" valueIsNumericString={true}
                                                 refs={{...register('tel', {
                                                         required: 'Musí být vyplňeno',
                                                     })}}
                                  />*/}
                                  {errors.tel && (<p className="mt-3 text-xs border-2 rounded bg-red-300 border-red-300 text-white"><ReportGmailerrorredIcon/> {errors.tel.message}</p>)}

                              </div>
                          </div>
                          <div className="flex flex-wrap -mx-3 mb-6">
                              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-stone-50"
                                         htmlFor="grid-first-name">
                                      Vaše Jméno *
                                  </label>
                                  <input
                                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white dark:bg-gray-900 dark:text-stone-50 dark:border-gray-900"
                                      id="grid-first-name" type="text" placeholder="Velká"  name="first_name" autoComplete="on"
                                      {...register('first_name', {
                                      required: 'Musí být vyplňeno',
                                  })}
                                  />
                                  {errors.first_name && (<p className="mb-2 text-xs border-2 rounded bg-red-300 border-red-300 text-white"><ReportGmailerrorredIcon/> {errors.first_name.message}</p>)}

                              </div>
                              <div className="w-full md:w-1/2 px-3">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-stone-50"
                                         htmlFor="grid-last-name">
                                      Vaše Přijmení *
                                  </label>
                                  <input
                                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 dark:bg-gray-900 dark:text-stone-50 dark:border-gray-900"
                                      id="grid-last-name" type="text" placeholder="Chobotnička" name="surname"
                                      {...register('surname', {
                                          required: 'Musí být vyplňeno',
                                      })}
                                  />
                                  {errors.surname && (<p className="mt-3 text-xs border-2 rounded bg-red-300 border-red-300 text-white"><ReportGmailerrorredIcon/> {errors.surname.message}</p>)}
                              </div>
                          </div>
                          <div className="flex flex-wrap -mx-3 mb-2">
                              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-stone-50"
                                         htmlFor="grid-adress">
                                      Adresa *
                                  </label>
                                  <input
                                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 dark:bg-gray-900 dark:text-stone-50 dark:border-gray-900"
                                      id="grid-adress" type="text" placeholder="U Chobotničky 1" name="adress"
                                      {...register('adress', {
                                          required: 'Musí být vyplňeno',
                                      })}
                                  />
                                  {errors.adress && (<p className="mt-3 text-xs border-2 rounded bg-red-300 border-red-300 text-white"><ReportGmailerrorredIcon/> {errors.adress.message}</p>)}

                              </div>

                              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 dark:text-stone-50"
                                         htmlFor="grid-city">
                                      Město/Obec *
                                  </label>
                                  <input
                                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 dark:bg-gray-900 dark:text-stone-50 dark:border-gray-900"
                                      id="grid-city" type="text" placeholder="Ostrava" name="city"
                                      {...register('city', {
                                          required: 'Musí být vyplňeno',
                                      })}
                                  />
                                  {errors.city && (<p className="mt-3 text-xs border-2 rounded bg-red-300 border-red-300 text-white"><ReportGmailerrorredIcon/> {errors.city.message}</p>)}

                              </div>
                          </div>
                          <div className="flex flex-wrap -mx-3 mb-2">
                              <div className="flex items-center mb-4 m-4">
                                  <input id="default-checkbox" type="checkbox" value=""
                                         className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" name="term"
                                         {...register('term', {
                                             required: 'Musí být potvrzeno',
                                         })}
                                  />
                                      <label htmlFor="default-checkbox"
                                             className="ml-2 text-sm font-light text-gray-900 dark:text-gray-300"><button className="sm:text-[14px] text-[9px] hover:text-fuchsia-800 dark:text-stone-50" type="button" data-te-toggle="modal" data-te-target="#exampleModal" data-te-ripple-init data-te-ripple-color="light">SOUHLASÍM SE <u className="font-bold">ZPRACOVÁNÍM OSOBNÍCH ÚDAJŮ</u> *</button> </label>
                          </div>

                          </div>
                          {errors.term && (<p className="mb-3 text-xs border-2 rounded bg-red-300 border-red-300 text-white"><ReportGmailerrorredIcon/> {errors.term.message}</p>)}
                          <div className="mb-4">
                              <ReCAPTCHA
                                  className="invisible"
                                  sitekey="6LfM-AcoAAAAAGdVixVqlnEDeJztDmFveLfWlpmP"
                                  ref={captchaRef}
                                  size="invisible"
                                  badge="bottomleft"
                              />
                          </div>
                          <div className="flex flex-wrap -mx-3 mb-2">
                              <div className="w-full px-3">
                                  <button type="submit" disabled={submitDisabled} className="w-full flex justify-center text-white bg-fuchsia-700 hover:bg-fuchsia-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-fuchsia-500 dark:hover:bg-fuchsia-600 focus:outline-none dark:focus:bg-fuchsia-500">{submitText}</button>
                              </div>
                          </div>

                      </form>

                      <ToastContainer
                          position="top-center"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme={themeToast}
                      />

                      <div data-te-modal-init className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div data-te-modal-dialog-ref className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px]">
                              <div className="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-gray-800">
                                  <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

                                      <h5 className="text-l font-medium leading-normal text-neutral-800 dark:text-neutral-200" id="exampleModalLabel">
                                          SOUHLAS SE ZPRACOVÁNÍM OSOBNÍCH ÚDAJŮ
                                      </h5>

                                      <button type="button" className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" data-te-modal-dismiss aria-label="Close">
                                          <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth="1.5"
                                              stroke="currentColor"
                                              className="h-6 w-6">
                                              <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M6 18L18 6M6 6l12 12"/>
                                          </svg>
                                      </button>
                                  </div>


                                  <div className="relative flex-auto px-6" data-te-modal-body-ref>
                                      <p className="sm:text-[15px] text-[8px] text-justify pb-2 leading-relaxed text-gray-500 dark:text-gray-400">
                                          Já níže podepsaná/ý vyjadřuji výslovný souhlas BABY CLUBU CHOBOTNIČKA k shromažďování a zpracování osobních údajů, týkajících se mé osoby a mého dítěte, uvedeného v této přihlášce pro účely evidence klientů kurzu plavání. Dále tímto výslovně prohlašuji, že jsem byl/a v souladu s ustanovením § 11 zákona č.101/2000 Sb. V platném znění o ochraně osobních údajů řádně informována o zpracování osobních údajů v souvislosti s účasti v kurzu plavání.
                                      </p>
                                      <ul className="sm:text-[15px] text-[8px] pb-2 list-disc text-justify ml-7 leading-relaxed text-gray-500 dark:text-gray-400">
                                          <li className="py-1">Do ceny kurzu je zahrnuto: 30 minut výuky plavání, 30 minut adaptace dítěte před a po lekci</li>
                                          <li className="py-1">Cenou za lekci se rozumí "pobyt jednoho rodiče a jednoho dítěte"</li>
                                          <li className="py-1">Kurzovné je nevratné, nepřevoditelné, nepřevoditelné na jiné dítě</li>
                                          <li className="py-1">Platnost permanentky je omezena počtem lekcí - počet týdnů v kurzu</li>
                                      </ul>

                                      <p className="sm:text-[15px] text-[8px] pb-2 text-justify leading-relaxed text-gray-500 dark:text-gray-400">
                                          Prohlašuji, že výše uvedené údaje jsou pravdivé a že jsem byl/a  seznámen s podmínkami a provozním řádem vztahující se na účast v kurzu plavání. Jsem si vědom(a), že na lekcích mohou vyučující/rodiče fotit pořizovat záznamy dětí ve výuce. Na záznamech mohou být i jiné děti ze skupinky Baby Clubu Chobotnička.
                                      </p>

                                      <p className="sm:text-[15px] text-[8px] text-justify leading-relaxed text-gray-500 dark:text-gray-400">
                                          Prohlašuji, že jsem já, ani rodinní příbuzní dítěte v posledních 15-ti dnech nepobývali mimo ČR, v oblastech s výskytem infekčních a nakažlivých onemocnění. Dítě nejeví známky akutního onemocnění, (průjem, chřipka, záněty spojivek apod.). Ošetřující lékař, ani hygienik nenařídil dítěti/naší rodině změnu režimu karanténní opatření. Není mi známo, že by v posledních dvou týdnech přišlo dítě do styku s osobami, které onemocněly přenosnou nemocí.
                                      </p>
                                  </div>


                                  <div
                                      className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                      <button
                                          type="button"
                                          className="inline-block rounded bg-fuchsia-700 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-accent-100 hover:text-black focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                          data-te-modal-dismiss
                                          data-te-ripple-init
                                          data-te-ripple-color="light">
                                          Souhlasím
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      </section>
  )
}


export default Contact